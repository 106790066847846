import React from "react";
import "./productCategory.css";
import { Link } from "react-router-dom";

const ProductCategory = ({ image, label, value }) => {
  return (
    <Link to={`/product-category/${value}`} className="product_category">
      <div>
        <img
          src={image}
          alt={`${label} product category`}
          width={"250px"}
          style={{
            objectFit: "contain",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}
        />
      </div>
      <div className="name_container">
        <p className="category_name">{label}</p>
      </div>
    </Link>
  );
};

export default ProductCategory;
