import React from "react";
import "./header.css";
import hero_image from "../../assets/images/hero_image.jpeg";
import Achievement from "../achievement/Achievement";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

const Header = () => {
  const container = React.useRef(null);
  useGSAP(
    () => {
      const timeline = gsap.timeline({ delay: 1, stagger: 1 });
      timeline
        .fromTo(
          ".image__container",
          { scale: 0.5, opacity: 0, y: 150 },
          { scale: 1, ease: "sine.in", opacity: 1, y: 0 }
        )
        .from(".title", { opacity: 0, y: -30 })
        .from(".description", { opacity: 0, y: -30 })
        .from(".buttons__container", { opacity: 0, y: 40 });
    },
    { scope: container }
  );

  return (
    <header id="header" ref={container}>
      <div className="full__height container">
        <div className="row">
          <div className="column">
            <h1 className="title">
              Your authentic branding <span className="g-text">&</span>{" "}
              packaging partner
            </h1>
            <p className="text__muted description">
              Leveraging our cutting-edge technology, we deliver premium
              printing, branding, and manufacturing solutions for a wide range
              of products nationwide. Our dedicated team of experts works
              tirelessly to ensure that every branded clothing line, printed
              material, and packaging solution not only meets but exceeds our
              customers’ expectations. Experience unmatched quality, innovative
              design, and reliable service—your trusted partner for transforming
              ideas into outstanding end products.
            </p>
          </div>
          <div className="column">
            <div className="image__container">
              <img
                src={hero_image}
                alt=""
                style={{ background: "transparent" }}
              />
            </div>
          </div>
        </div>
        <Achievement />
      </div>
    </header>
  );
};

export default Header;
