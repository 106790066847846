import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { registerUser } from "../redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import "./css/SignUpPage.css";
import ButtonLoading from "../components/shimmers/ButtonLoading";

const userRegistrationValidationSchema = Yup.object({
  name: Yup.string().required("A user name is required."),
  email: Yup.string()
    .email("Invalid email.")
    .required("An email address is required."),
  password: Yup.string()
    .min(4, "Password is too short, must contain at least 4 characters.")
    .required("Password is required."),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords don't match")
    .required("Password is required."),
});

const SignUpPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const redirect = "/";
  const { loadingUserRegistration, errorRegistration, userInfo } = useSelector(
    (state) => state.user
  );

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const initialValues = {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  React.useEffect(() => {
    if (userInfo) {
      if (location.state?.from) {
        navigate(location.state.from);
      } else {
        navigate(redirect);
      }
    }
  }, [userInfo, navigate, redirect, location.state]);

  return (
    <section id="signup_page" className="page">
      <div className="signup_container">
        <div className="signup_card">
          <div className="error_message_container">
            {errorRegistration && (
              <p className="error_text">{errorRegistration}</p>
            )}
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={userRegistrationValidationSchema}
            onSubmit={(values) => {
              dispatch(registerUser(values));
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form className="signup_form" onSubmit={handleSubmit}>
                <div className="form_group">
                  <label>User name :</label>
                  <div className="input_container">
                    <input
                      type="text"
                      placeholder="Name"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      className="form_input"
                    />
                  </div>
                  {touched.name && errors.name && (
                    <p className="error_text_small">{`*${errors.name}`}</p>
                  )}
                </div>
                <div className="form_group">
                  <label>Email :</label>
                  <div className="input_container">
                    <input
                      type="text"
                      placeholder="Email"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      className="form_input"
                    />
                  </div>
                  {touched.email && errors.email && (
                    <p className="error_text_small">{`*${errors.email}`}</p>
                  )}
                </div>
                <div className="form_group">
                  <label>Password :</label>
                  <div className="input_container password_container">
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      name="password"
                      onChange={handleChange}
                      value={values.password}
                      className="form_input"
                    />
                    <div
                      className="password_toggle"
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      {showPassword ? <FaEye /> : <FaEyeSlash />}
                    </div>
                  </div>
                  {touched.password && errors.password && (
                    <p className="error_text_small">{`*${errors.password}`}</p>
                  )}
                </div>
                <div className="form_group">
                  <label>Confirm Password :</label>
                  <div className="input_container password_container">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      onChange={handleChange}
                      value={values.confirmPassword}
                      className="form_input"
                    />
                    <div
                      className="password_toggle"
                      onClick={() => setShowConfirmPassword((prev) => !prev)}
                    >
                      {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                    </div>
                  </div>
                  {touched.confirmPassword && errors.confirmPassword && (
                    <p className="error_text_small">{`*${errors.confirmPassword}`}</p>
                  )}
                </div>
                {loadingUserRegistration ? (
                  <ButtonLoading />
                ) : (
                  <button className="signup_button" type="submit">
                    <p> Sign Up</p>
                  </button>
                )}
              </form>
            )}
          </Formik>
          <p className="signin_text">
            Already have an account?{" "}
            <Link to={"/login"} className="signin_link">
              Sign In
            </Link>
          </p>
        </div>
      </div>
    </section>
  );
};

export default SignUpPage;
