import React from "react";
import "./contactCard.css";
import { FaArrowRight } from "react-icons/fa6";

const ContactCard = ({ icon, alt, title, content, action, to }) => {
  return (
    <div className="contact_card">
      <div className="card_icon">
        <img src={icon} alt={alt} style={{ width: "40px", height: "auto" }} />
      </div>

      <div className="details">
        <div className="details_container">
          <p className="detail_title">{title}</p>
          <p className="detail_content">{content}</p>
        </div>
        <div>
          <a
            className="link_container"
            href={to}
            target="_blank"
            rel="noopener noreferrer"
          >
            <p className="link_text">{action}</p>
            <FaArrowRight />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
