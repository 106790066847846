import React from "react";

import ScrollToTop from "./utils/ScrollToTop";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Navbar from "./components/navigation/Navbar";

import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import SignUpPage from "./pages/SignUpPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import EmailVerificationPage from "./pages/EmailVerificationPage";
import AdminPanel from "./pages/AdminPanel";
import AdminDashboardPage from "./pages/AdminDashboardPage";
import AdminOrdersPage from "./pages/AdminOrdersPage";
import ProductCategoryPage from "./pages/ProductCategoryPage";
import ProductPage from "./pages/ProductPage";
import Footer from "./components/footer/Footer";
import ServicesPage from "./pages/ServicesPage";
import CartPage from "./pages/CartPage";
import AboutPage from "./pages/AboutPage";

const App = () => {
  return (
    <Router>
      <ToastContainer />
      <Navbar />

      <main>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/services" element={<ServicesPage />} />
            <Route path="/cart" element={<CartPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/sign-up" element={<SignUpPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route
              path="/email-verify/:token"
              element={<EmailVerificationPage />}
            />
            <Route path="/product/:id" element={<ProductPage />} />
            <Route path="/admin-panel" element={<AdminPanel />}>
              <Route path="dashboard" element={<AdminDashboardPage />} />
              <Route path="admin-orders" element={<AdminOrdersPage />} />
            </Route>
            <Route
              path="/product-category/:category"
              element={<ProductCategoryPage />}
            />
          </Routes>
        </ScrollToTop>
      </main>

      <Footer />
    </Router>
  );
};

export default App;
