import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loadingProducts: false,
  loadingProduct: false,
  loadingCategoryProducts: false,
  errorLoadingProducts: null,
  errorLoadingProduct: null,
  errorLoadingCategoryProducts: null,
  product: null,
  products: [],
  categoryProducts: [],
};

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setLoadingProducts: (state) => {
      state.loadingProducts = true;
      state.serverStatus = null;
    },
    setLoadingProduct: (state) => {
      state.loadingProduct = true;
      state.serverStatus = null;
    },
    setLoadingCategoryProducts: (state) => {
      state.loadingCategoryProducts = true;
      state.serverStatus = null;
    },
    setErrorLoadingProducts: (state, { payload }) => {
      state.loadingProducts = false;
      state.errorLoadingProducts = payload;
    },
    setErrorLoadingProduct: (state, { payload }) => {
      state.loadingProduct = false;
      state.errorLoadingProduct = payload;
    },
    setErrorLoadingCategoryProducts: (state, { payload }) => {
      state.loadingCategoryProducts = false;
      state.errorLoadingCategoryProducts = payload;
    },
    setProducts: (state, { payload }) => {
      state.loadingProducts = false;
      state.errorLoadingProducts = null;
      state.products = payload;
    },
    setProduct: (state, { payload }) => {
      state.loadingProduct = false;
      state.errorLoadingProduct = null;
      state.product = payload;
    },
    setCategoryProducts: (state, { payload }) => {
      state.loadingCategoryProducts = false;
      state.errorLoadingCategoryProducts = null;
      state.categoryProducts = payload;
    },
  },
});

export const {
  setCategoryProducts,
  setErrorLoadingCategoryProducts,
  setErrorLoadingProducts,
  setLoadingCategoryProducts,
  setLoadingProducts,
  setProducts,
  setErrorLoadingProduct,
  setLoadingProduct,
  setProduct,
} = productsSlice.actions;

export default productsSlice.reducer;

export const productSelector = (state) => state.products;
