import { createSlice } from "@reduxjs/toolkit";

const calculateSubtotal = (cartState) => {
  let result = 0;
  cartState.map((item) => (result += item.qty * item.price));
  return result;
};

export const initialState = {
  loadingCartItems: false,
  errorLoadingCartItems: null,
  cartItems: JSON.parse(localStorage.getItem("cartItems")) ?? [],
  deliveryFee: JSON.parse(localStorage.getItem("deliveryFee")) ?? Number(1000),
  subtotal: localStorage.getItem("cartItems")
    ? calculateSubtotal(JSON.parse(localStorage.getItem("cartItems")))
    : 0,
};

const updateLocalStorage = (cart) => {
  localStorage.setItem("cartItems", JSON.stringify(cart));
  localStorage.setItem("subtotal", JSON.stringify(calculateSubtotal(cart)));
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setLoadingCartItems: (state) => {
      state.loadingCartItems = true;
    },
    setErrorLoadingCartItems: (state, { payload }) => {
      state.errorLoadingCartItems = payload;
      state.loadingCartItems = false;
    },
    cartItemAdd: (state, { payload }) => {
      const existingItem = state.cartItems.find(
        (item) => item.id === payload.id
      );

      if (existingItem) {
        state.cartItems = state.cartItems.map((item) =>
          item.id === existingItem.id ? payload : item
        );
      } else {
        state.cartItems = [...state.cartItems, payload];
      }
      state.loadingCartItems = false;
      state.errorLoadingCartItems = null;
      updateLocalStorage(state.cartItems);
      state.subtotal = Number(calculateSubtotal(state.cartItems));
    },
    cartItemRemoval: (state, { payload }) => {
      state.cartItems = [...state.cartItems].filter(
        (item) => item.id !== payload
      );
      updateLocalStorage(state.cartItems);
      state.subtotal = calculateSubtotal(state.cartItems);
      state.loadingCartItems = false;
      state.errorLoadingCartItems = null;
    },
    setShippingCosts: (state, { payload }) => {
      state.deliveryFee = payload;
      localStorage.setItem("deliveryFee", payload);
    },
    clearCart: (state) => {
      localStorage.removeItem("cartItems");
      localStorage.removeItem("deliveryFee");
      localStorage.removeItem("subTotal");
      state.cartItems = [];
      state.deliveryFee = Number(4.99);
      state.subtotal = 0;
      state.loadingCartItems = false;
      state.errorLoadingCartItems = null;
    },
  },
});

export const {
  setErrorLoadingCartItems,
  setLoadingCartItems,
  cartItemAdd,
  cartItemRemoval,
  setShippingCosts,
  clearCart,
} = cartSlice.actions;
export default cartSlice.reducer;

export const cartSelector = (state) => state.cart;
