import React from "react";
import "./css/productCategoryPage.css";
import CategoryPageHeader from "../components/headers/CategoryPageHeader";
import { useParams } from "react-router-dom";
import CategorizedProducts from "../components/products/CategorizedProducts";

const ProductCategoryPage = () => {
  const { category } = useParams();
  return (
    <div id={"page"} className="page">
      <div>
        <CategoryPageHeader category={category} />
      </div>
      <div className="products_container">
        <CategorizedProducts category={category} />
      </div>
    </div>
  );
};

export default ProductCategoryPage;
