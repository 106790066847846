import { IoCallOutline, IoLocationOutline } from "react-icons/io5";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { FaDrawPolygon } from "react-icons/fa";
import { CiLinkedin, CiFacebook } from "react-icons/ci";
import { SiBlueprint, SiHomeassistantcommunitystore } from "react-icons/si";
import { AiOutlineFileProtect } from "react-icons/ai";
import { IoConstructSharp } from "react-icons/io5";
import { GrUserManager } from "react-icons/gr";

export const services = [
  {
    name: "General Contracting",
    icon: <SiBlueprint />,
    description: `This involves overseeing the entire construction project from start to finish, 
        including hiring subcontractors, managing the budget, scheduling, and ensuring that the
        project meets all specifications and regulations.`,
  },
  {
    name: "Design-Build Services",
    icon: <FaDrawPolygon />,
    description: `This service combines both the design and construction phases into a single 
       contract, streamlining the process and improving communication between the design and
        construction teams.

    `,
  },
  {
    name: "Project Management",
    icon: <GrUserManager />,
    description: `Construction companies often provide project management services, which include 
       planning, coordinating, and overseeing projects to ensure they are completed on time, within 
       budget, and to the required quality standards.`,
  },
  {
    name: "Renovation and Remodeling",
    icon: <SiHomeassistantcommunitystore />,
    description: `Many construction companies specialize in renovation and remodeling services, 
                which involve updating and improving existing structures. This can range from minor 
                repairs to major overhauls of residential, commercial, or industrial properties.`,
  },
  {
    name: "Site Preparation",
    icon: <AiOutlineFileProtect />,
    description: `Before construction begins, companies often handle site preparation tasks such 
    as land clearing, excavation, grading, and utility installation to ensure the site is ready 
    for building.`,
  },
  {
    name: "Specialty Construction Services",
    icon: <IoConstructSharp />,
    description: `This can include services like roofing, concrete work, steel erection, plumbing, 
    electrical work, and other specialized trades necessary for specific aspects of the construction project.`,
  },
];

export const contacts = [
  {
    name: "Email",
    value: "walterkagwima@gmail.com",
    icon: <MdOutlineAlternateEmail />,
  },
  {
    name: "Phone Number",
    value: "+254 746231553",
    icon: <IoCallOutline />,
  },
  {
    name: "Address",
    value: "Fiji, Nairobi, Kenya",
    icon: <IoLocationOutline />,
  },
];

export const footer = [
  { name: "Home", to: "/" },
  { name: "About Us", to: "/about" },
  { name: "Services", to: "/services" },
  { name: "Clients", to: "/clients" },
];
