import React from "react";
import "./navbar.css";
import { navTabs } from "../../utils/navTabs";
import { Link, NavLink } from "react-router-dom";
import Logo from "../logo/Logo";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { GiShoppingCart } from "react-icons/gi";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdOutlineClose } from "react-icons/md";
import { useSelector } from "react-redux";

const Navbar = () => {
  const container = React.useRef(null);
  const { cartItems } = useSelector((state) => state.cart);
  const { userInfo } = useSelector((state) => state.user);
  const [openNavBar, setOpenNavBar] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const handleScroll = () => {
    const currentScrollPosition = window.scrollY;
    if (currentScrollPosition > 145) {
      return setVisible(true);
    }
    setVisible(false);
  };
  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  React.useEffect(() => {
    if (visible) {
      gsap.fromTo(".navbar", { y: -250 }, { y: 0, top: 0 });
    }
  }, [visible]);

  useGSAP(
    () => {
      const timeline = gsap.timeline({ stagger: 0.5 });
      timeline
        .from(".logo", { opacity: 0, x: -100, delay: 1 })
        .from(".nav_link", { opacity: 0, stagger: 0.5 })
        .from(".buttons", { opacity: 0, x: 100 });
    },
    { scope: container }
  );
  return (
    <nav className={`navbar ${visible ? "visible" : ""}`} ref={container}>
      <Link to={"/"}>
        <Logo />
      </Link>
      <div className="desktop_navigation">
        <div className={`box nav__tabs ${openNavBar ? "open" : ""}`}>
          {navTabs.map((tab, index) => (
            <NavLink
              className={({ isActive }) =>
                isActive ? "nav_link active" : "nav_link"
              }
              to={tab.to}
              smooth="true"
              spy="true"
              offset={-70}
              key={index}
              onClick={() => setOpenNavBar(false)}
            >
              <p>{tab.label}</p>
            </NavLink>
          ))}
        </div>
        <div className="box buttons">
          {userInfo === null ? (
            <Link to={"/login"} className="sign_btn">
              Sign In
            </Link>
          ) : (
            userInfo && <></>
          )}

          <GiShoppingCart size={"50px"} />
          <div className="cart_container">
            <span className="cart_count">{cartItems.length}</span>
          </div>
        </div>
      </div>
      <div className="mobile_navigation">
        {!openNavBar && (
          <div className="buttons">
            <button onClick={() => setOpenNavBar(true)} className="nav_button">
              <RxHamburgerMenu size={"25px"} />
            </button>
          </div>
        )}
        {openNavBar && (
          <div className="mobile_nav_menu">
            <div className="nav_header">
              <p className="nav_title">Menu</p>
              <button
                onClick={() => setOpenNavBar(false)}
                className="nav_button"
              >
                <MdOutlineClose size={"25px"} color="red" />
              </button>
            </div>
            <div className="mobile_links">
              {navTabs.map((tab, index) => (
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "nav_link active" : "nav_link"
                  }
                  to={tab.to}
                  smooth="true"
                  spy="true"
                  offset={-70}
                  key={index}
                  onClick={() => setOpenNavBar(false)}
                >
                  <p className="mobile_link">{tab.label}</p>
                </NavLink>
              ))}
            </div>

            {userInfo === null ? (
              <div className="mobile_sign_container">
                <Link
                  to={"/login"}
                  className="mobile_sign_btn"
                  onClick={() => setOpenNavBar(false)}
                >
                  <p className="mobile_sign">Sign In</p>
                </Link>
              </div>
            ) : (
              userInfo && <></>
            )}
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
