import React from "react";
import Banner from "../components/Banner";
import Header from "../components/headers/Header";
import Services from "../components/services/Services";
import Contact from "../components/contact/Contact";

const HomePage = () => {
  return (
    <div className="page">
      <Header />
      <Services />
      <Contact />
      {/* <Banner /> */}
    </div>
  );
};

export default HomePage;
