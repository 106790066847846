import React from "react";
import "./css/aboutPage.css";

const AboutPage = () => {
  return (
    <div id="about_page" className="page">
      <h1>about</h1>
    </div>
  );
};

export default AboutPage;
