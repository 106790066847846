import React from "react";
import "./categoryPageHeader.css";

const CategoryPageHeader = ({ category }) => {
  return (
    <div className="title_container">
      <svg width="100%" height="80" viewBox="0 0 300 80">
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={{ stopColor: "red", stopOpacity: 1 }} />
            <stop offset="25%" style={{ stopColor: "green", stopOpacity: 1 }} />
            <stop offset="50%" style={{ stopColor: "blue", stopOpacity: 1 }} />
            <stop
              offset="75%"
              style={{ stopColor: "purple", stopOpacity: 1 }}
            />
            <stop
              offset="100%"
              style={{ stopColor: "orange", stopOpacity: 1 }}
            />
          </linearGradient>
        </defs>
        <text
          className="title"
          x="50%"
          y="55"
          fontSize="70"
          fill="url(#gradient)"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {category}
        </text>
      </svg>
    </div>
  );
};

export default CategoryPageHeader;
