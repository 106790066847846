import React from "react";
import { useSwipeable } from "react-swipeable";
import "./productCategoryList.css";
import { useDispatch, useSelector } from "react-redux";
import { getProductCategories } from "../../redux/actions/categoryActions";
import ProductCategory from "./ProductCategory";
import RectangularLoadingShimmer from "../shimmers/RectangularLoadingShimmer";

const ProductCategoryList = () => {
  const dispatch = useDispatch();
  const containerRef = React.useRef(null);
  const {
    loadingProductCategories,
    errorLoadingProductCategories,
    productCategories,
  } = useSelector((state) => state.category);

  const loadingFill = new Array(5).fill(null);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      containerRef.current?.scrollBy({ left: 300, behavior: "smooth" });
    },
    onSwipedRight: () => {
      containerRef.current?.scrollBy({ left: -300, behavior: "smooth" });
    },
    trackMouse: true,
  });

  React.useEffect(() => {
    dispatch(getProductCategories());
  }, [dispatch]);

  return (
    <div className="category_container">
      <div>
        {loadingProductCategories ? (
          <div className="loading_container">
            {loadingFill.map((loadItem, index) => (
              <RectangularLoadingShimmer key={index} />
            ))}
          </div>
        ) : errorLoadingProductCategories ? (
          <div className="error_container">
            <div className="message_container">
              <h1 className="error_title">Error!</h1>
              <h2 className="error_message">
                {" "}
                {errorLoadingProductCategories}
              </h2>
            </div>
          </div>
        ) : (
          productCategories && (
            <div {...swipeHandlers} ref={containerRef} className="categories">
              {productCategories.map((category) => (
                <ProductCategory
                  key={category._id}
                  image={category.image}
                  label={category.label}
                  value={category.value}
                />
              ))}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ProductCategoryList;
