import React from "react";
import "./categorizedProducts.css";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryProducts } from "../../redux/actions/productActions";
import ProductCard from "./ProductCard";
import RectangularLoadingShimmer from "../shimmers/RectangularLoadingShimmer";

const CategorizedProducts = ({ category }) => {
  const dispatch = useDispatch();
  const {
    loadingCategoryProducts,
    errorLoadingCategoryProducts,
    categoryProducts,
  } = useSelector((state) => state.product);

  const loadingFill = new Array(10).fill(null);

  React.useEffect(() => {
    dispatch(getCategoryProducts(category));
  }, [category, dispatch]);

  return (
    <div id="products_container">
      {loadingCategoryProducts ? (
        <div className="loading_container">
          {loadingFill.map((loadItem, index) => (
            <RectangularLoadingShimmer key={index} />
          ))}
        </div>
      ) : errorLoadingCategoryProducts ? (
        <div className="error_container">
          <div className="message_container">
            <h1 className="error_title">Error!</h1>
            <h2 className="error_message"> {errorLoadingCategoryProducts}</h2>
          </div>
        </div>
      ) : categoryProducts.length === 0 ? (
        <div className="zer_container">
          <div className="message_container">
            <h1 className="title">Oops!</h1>
            <h2 className="message">No Products from this category.</h2>
          </div>
        </div>
      ) : (
        categoryProducts && (
          <div className="products">
            {categoryProducts.map((categoryProduct) => (
              <ProductCard
                key={categoryProduct._id}
                id={categoryProduct._id}
                name={categoryProduct.name}
                description={categoryProduct.description}
                images={categoryProduct.images}
              />
            ))}
          </div>
        )
      )}
    </div>
  );
};

export default CategorizedProducts;
