import React from "react";
import "./css/productPage.css";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProductById } from "../redux/actions/productActions";
import RatingStars from "../components/rating/RatingStars";
import PageLoadingShimmer from "../components/shimmers/PageLoadingShimmer";

const ProductPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loadingProduct, errorLoadingProduct, product } = useSelector(
    (state) => state.product
  );
  const [selectedImage, setSelectedImage] = React.useState(0);
  const [pieces, setPieces] = React.useState(100);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  React.useEffect(() => {
    dispatch(getProductById(id));
  }, [dispatch, id]);
  return (
    <div id="product_page">
      {loadingProduct ? (
        <div>
          <PageLoadingShimmer />
        </div>
      ) : errorLoadingProduct ? (
        <div className="error_container">
          <div className="message_container">
            <h1 className="error_title">Error!</h1>
            <h2 className="error_message"> {errorLoadingProduct}</h2>
          </div>
        </div>
      ) : (
        product && (
          <div className="product_container">
            <div className="content_container">
              <div className="card">
                <div className="card_content_grid">
                  <div className="image_gallery">
                    <div className="main_image_container">
                      <img
                        src={product.images[selectedImage]}
                        alt={product.name}
                        className="main_image"
                      />
                    </div>
                    <div className="thumbnails_grid">
                      {product.images.map((image, index) => (
                        <button
                          key={index}
                          onClick={() => setSelectedImage(index)}
                          className={`thumbnail_button ${
                            selectedImage === index ? "selected_thumbnail" : ""
                          }`}
                        >
                          <img
                            src={image}
                            alt={`${product.name} ${index + 1}`}
                            className="thumbnail_image"
                          />
                        </button>
                      ))}
                    </div>
                  </div>

                  {/* Product Details */}
                  <div className="product_details">
                    <div className="product_header">
                      {product.productIsNew && (
                        <span className="new_arrival">New Arrival</span>
                      )}
                      <h1 className="product_name">{product.name}</h1>
                      <p className="brand_name">{product.field}</p>
                    </div>

                    <div className="rating_container">
                      <RatingStars rating={product.rating} />
                      <span className="reviews_count">
                        {`(${product.numberOfReviews} ${
                          product.reviews.length === 1 ? "review" : "reviews"
                        })`}
                      </span>
                    </div>
                    <div className="description_section">
                      <h3 className="description_title">Description</h3>
                      <p className="description_text">{product.description}</p>
                    </div>

                    <div className="order_container">
                      <p>Pieces to order:</p>
                      <input
                        type="number"
                        placeholder="No. of pieces"
                        value={pieces}
                        onChange={(e) => setPieces(e.target.value)}
                        className="order_input"
                      />
                    </div>

                    <button className="cart_button" onClick={handleSubmit}>
                      <p className="cart_button_text">Add to Cart</p>
                    </button>
                  </div>
                </div>

                {/* Reviews Section */}
                <div className="reviews_section">
                  <h2 className="reviews_title">Customer Reviews</h2>
                  <div className="reviews_list">
                    {product.reviews.length === 0 ? (
                      <div className="no_reviews_container">
                        <h2 className="no_review_text">No Reviews Yet!</h2>
                      </div>
                    ) : (
                      product.reviews &&
                      product.reviews.map((review, index) => (
                        <div key={index} className="review_item">
                          <div className="review_header">
                            <h3 className="review_title">{review.title}</h3>
                            <RatingStars rating={review.rating} />
                          </div>
                          <p className="review_author">by {review.name}</p>
                          <p className="review_comment">{review.comment}</p>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default ProductPage;
