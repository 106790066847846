import React from "react";
import "./achievement.css";
import { FaDiagramProject, FaUsersLine } from "react-icons/fa6";
import ReactOdometer from "react-odometerjs";
import { GiTeamIdea } from "react-icons/gi";
import { FaAward } from "react-icons/fa";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

const Achievement = () => {
  const container = React.useRef(null);
  const [clients, setClients] = React.useState(0);
  const [projects, setProjects] = React.useState(0);
  const [teams, setTeams] = React.useState(0);
  const [awards, setAwards] = React.useState(0);

  React.useEffect(() => {
    const timeOutId = setTimeout(() => {
      setClients(120);
      setProjects(150);
      setTeams(44);
      setAwards(89);
    }, 300);

    return () => clearTimeout(timeOutId);
  }, []);

  useGSAP(
    () => {
      const timeline = gsap.timeline();
      timeline.from(".achievement", {
        delay: 1.5,
        x: 100,
        stagger: 0.5,
        opacity: 0,
      });
    },
    { scope: container }
  );

  return (
    <div className="achievement__container" ref={container}>
      <div className="achievement">
        <div className="icon__container">
          <FaUsersLine />
        </div>
        <div className="details">
          <div className="count__row">
            <ReactOdometer value={clients} className="title" />
            <h1 className="g-text title">K</h1>
          </div>
          <small className="text__muted">Happy Customers</small>
        </div>
      </div>

      <div className="achievement">
        <div className="icon__container">
          <FaDiagramProject />
        </div>
        <div className="details">
          <div className="count__row">
            <ReactOdometer value={projects} className="title" />
            <h1 className="g-text title">+</h1>
          </div>
          <small className="text__muted">Completed Projects</small>
        </div>
      </div>

      <div className="achievement">
        <div className="icon__container">
          <GiTeamIdea />
        </div>
        <div className="details">
          <div className="count__row">
            <ReactOdometer value={teams} className="title" />
            <h1 className="g-text title">+</h1>
          </div>
          <small className="text__muted">Expert Workers</small>
        </div>
      </div>

      <div className="achievement">
        <div className="icon__container">
          <FaAward />
        </div>
        <div className="details">
          <div className="count__row">
            <ReactOdometer value={awards} className="title" />
            <h1 className="g-text title">+</h1>
          </div>
          <small className="text__muted">Awards Winning</small>
        </div>
      </div>
    </div>
  );
};

export default Achievement;
