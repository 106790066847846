import React from "react";
import "./logo.css";
import logo from "../../assets/images/blueHubLogoT.png";

const Logo = () => {
  return (
    <div className="logo">
      <div className="logo__container ">
        <img
          src={logo}
          alt="logo"
          width="80px"
          height="80px"
          style={{ objectFit: "contain" }}
        />
      </div>
      <h1 className="name">
        Bluehub<span>Industries</span>
      </h1>
    </div>
  );
};

export default Logo;
