import React from "react";
import "./services.css";
import gsap from "gsap";
import ProductCategoryList from "../categories/ProductCategoryList";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Services = () => {
  const container = React.useRef(null);
  useGSAP(
    () => {
      const timeline = gsap.timeline({
        delay: 0.5,
        scrollTrigger: {
          trigger: container.current,
          start: "20% bottom",
          end: "bottom top",
        },
      });

      timeline
        .from(".title", {
          y: -50,
          opacity: 0,
        })
        .from(".sub__title", {
          y: -50,
          opacity: 0,
        })
        .fromTo(
          ".product_category",
          {
            y: 100,
            opacity: 0,
          },
          { opacity: 1, stagger: 0.5, y: 0 }
        );
    },
    { scope: container }
  );
  return (
    <section id="products" ref={container}>
      <div>
        <div>
          <h1 className="title">
            Our
            <span className="g-text">Products</span>
          </h1>
          {/* <h3 className="sub__title">We specialize in.</h3> */}
        </div>
        <div className="products__container">
          <ProductCategoryList />
        </div>
      </div>
    </section>
  );
};

export default Services;
