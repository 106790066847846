/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import "./productCard.css";
import { Link } from "react-router-dom";

const ProductCard = ({ id, name, description, images }) => {
  return (
    <div className="product_container">
      <div className="product">
        <Link to={`/product/${id}`}>
          <div className="details_container">
            <div className="name_container">
              <h2 className="name">{name}</h2>
            </div>
            <div className="desc_container">
              <p className="description">{description}</p>
            </div>
          </div>
          <div className="image_container">
            <img
              src={images[0]}
              alt={`${name} image`}
              style={{ objectFit: "contain" }}
            />
          </div>
        </Link>
      </div>
      <div className="button_container">
        <button className="cart_button">
          <p className="button_text">Add To Cart</p>
        </button>
      </div>
    </div>
  );
};

export default ProductCard;
