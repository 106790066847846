import { ipAddress } from "../constants";
import axios from "axios";

const axiosInstance = axios.create({
  baseURL: `${ipAddress}/api/v1`,
  headers: {
    Authorization:
      "Basic " +
      btoa(
        `${process.env.REACT_APP_API_USERNAME}:${process.env.REACT_APP_API_PASSWORD}`
      ),
  },
});

export default axiosInstance;
