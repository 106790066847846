import React from "react";
import loginIcon from "../assets/images/signin.gif";
import { Formik } from "formik";
import * as Yup from "yup";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { loginUser } from "../redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import "./css/LoginPage.css";
import ButtonLoading from "../components/shimmers/ButtonLoading";

const userLoginValidationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email.")
    .required("An email address is required."),
  password: Yup.string()
    .min(4, "Password is too short, must contain at least 4 characters.")
    .required("Password is required."),
});

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const redirect = "/";
  const { loadingUserInfo, errorLogingIn, userInfo } = useSelector(
    (state) => state.user
  );

  const [showPassword, setShowPassword] = React.useState(false);

  const initialValues = {
    email: "",
    password: "",
  };

  React.useEffect(() => {
    if (userInfo) {
      if (location.state?.from) {
        navigate(location.state.from);
      } else {
        navigate(redirect);
      }
    }
  }, [userInfo, navigate, redirect, location.state]);

  return (
    <section id="login_page" className="page">
      <div className="login_container">
        <div className="login_card">
          <div className="error_message_container">
            {errorLogingIn && <p className="error_text">{errorLogingIn}</p>}
          </div>
          <div className="login_icon_container">
            <img src={loginIcon} alt="login icons" />
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={userLoginValidationSchema}
            onSubmit={(values) => {
              dispatch(loginUser(values));
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form className="login_form" onSubmit={handleSubmit}>
                <div className="form_group">
                  <label>Email :</label>
                  <div className="input_container">
                    <input
                      type="text"
                      placeholder="Email"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      className="form_input"
                    />
                  </div>
                  {touched.email && errors.email && (
                    <p className="error_text_small">{`*${errors.email}`}</p>
                  )}
                </div>
                <div className="form_group">
                  <label>Password :</label>
                  <div className="input_container password_container">
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      value={values.password}
                      name="password"
                      onChange={handleChange}
                      className="form_input"
                    />
                    <div
                      className="password_toggle"
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      {showPassword ? <FaEye /> : <FaEyeSlash />}
                    </div>
                  </div>
                  {touched.password && errors.password && (
                    <p className="error_text_small">{`*${errors.password}`}</p>
                  )}
                </div>
                <div className="forgot_password">
                  <Link
                    to={"/forgot-password"}
                    className="forgot_password_link"
                  >
                    Forgot password?
                  </Link>
                </div>
                {loadingUserInfo ? (
                  <ButtonLoading />
                ) : (
                  <button className="login_button" type="submit">
                    <p>Login</p>
                  </button>
                )}
              </form>
            )}
          </Formik>
          <p className="signup_text">
            Don't have account?{" "}
            <Link to={"/sign-up"} className="signup_link">
              Sign up
            </Link>
          </p>
        </div>
      </div>
    </section>
  );
};

export default LoginPage;
