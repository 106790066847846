import React from "react";
import "./buttonLoading.css";

const ButtonLoading = () => {
  return (
    <div className="spinner_container">
      <div className="spinner"></div>
      Loading...
    </div>
  );
};

export default ButtonLoading;
