import React from "react";
import "./contact.css";
import ContactCard from "./ContactCard";
import callIcon from "../../assets/icons/phone-call-telephone-svgrepo-com.svg";
import messageIcon from "../../assets/icons/message-center-svgrepo-com.svg";
import whatsAppIcon from "../../assets/icons/whatsapp-svgrepo-com.svg";
import gmailIcon from "../../assets/icons/gmail-svgrepo-com.svg";
import top_banner from "../../assets/images/top_banner.jpg";

const Contact = () => {
  return (
    <div id="contact">
      <div className="header_container">
        <h1 className="title">
          Contact
          <span className="g-text"> us</span>
        </h1>
        <p className="header_desc">
          Our team is committed to supporting you with the highest quality
          services.
        </p>
      </div>

      <div className="card_container">
        <ContactCard
          icon={whatsAppIcon}
          alt={"WhatsApp"}
          title={"WhatsApp"}
          content={"0755550000"}
          action={"Chat now"}
          to={"https://wa.me/254755550000"}
        />
        <ContactCard
          icon={gmailIcon}
          alt={"Email"}
          title={"Email"}
          content={"bluehub@gmail.com"}
          action={"Email now"}
          to={"mailto:info@bluehub.co.ke?subject=Hello&body=Hi there,"}
        />

        <ContactCard
          icon={callIcon}
          alt={"Phone Call"}
          title={"Call"}
          content={"0755550000"}
          action={"Call now"}
          to={"#"}
        />

        <ContactCard
          icon={messageIcon}
          alt={"Text SMS"}
          title={"Message"}
          content={"0755550000"}
          action={"SMS now"}
          to={"#"}
        />
      </div>
      <div className="flexEnd c-right">
        <div className="image-container">
          <img
            src={top_banner}
            alt=""
            loading="lazy"
            style={{ background: "transparent" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Contact;
