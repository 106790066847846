import React from "react";
import "./footer.css";
import Logo from "../logo/Logo";
import { footer } from "../../data";
import { Link } from "react-scroll";
import tiktokIcon from "../../assets/icons/tiktok-svgrepo-com.svg";
import InstagramIcon from "../../assets/icons/instagram-1-svgrepo-com.svg";
import FacebookIcon from "../../assets/icons/facebook-color-svgrepo-com.svg";
import XIcon from "../../assets/icons/x-social-media-logo-icon.svg";

const Footer = () => {
  return (
    <footer id="footer" className="dark-mode">
      <div className="container">
        <div className="row">
          <div className="column">
            <Logo />
          </div>
          <div className="column">
            <div className="routes__name">Quick Links</div>
            <div className="routes__container">
              {footer.map((item, index) => (
                <Link key={index} className="route__item" to={item.to}>
                  <p className="name">{item.name}</p>
                </Link>
              ))}
            </div>
          </div>
          <div className="column">
            <div className="routes__name">Follow Us</div>
            <div className="social_links">
              <a
                className="social_link"
                href="https://www.tiktok.com/@bluehub.industries?_t=ZM-8v25jxNzdhN&_r=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={tiktokIcon}
                  alt="Tiktok"
                  style={{ width: "40px", height: "auto" }}
                />
              </a>

              <a
                className="social_link"
                href="https://www.instagram.com/bluehubindustries/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={InstagramIcon}
                  alt="Instagram"
                  style={{ width: "40px", height: "auto" }}
                />
              </a>
              <a
                className="social_link"
                href="https://www.facebook.com/profile.php?id=100008711946463"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={FacebookIcon}
                  alt="Facebook"
                  style={{ width: "40px", height: "auto" }}
                />
              </a>

              <Link className="social_link" to="#">
                <img
                  src={XIcon}
                  alt="X"
                  style={{ width: "40px", height: "auto" }}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="container">
          <h3>&copy; All rights reserved</h3>
          <p className="text__muted">A product By FIJI IO</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
