import { Star, StarHalf } from "lucide-react";
import "./ratingStars.css";

const RatingStars = ({ rating }) => {
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating % 1 !== 0;

  return (
    <div className="rating_stars">
      {[...Array(fullStars)].map((_, i) => (
        <Star key={i} className="star_icon" />
      ))}
      {hasHalfStar && <StarHalf className="star_icon" />}
      {[...Array(5 - Math.ceil(rating))].map((_, i) => (
        <Star key={i} className="star_empty" />
      ))}
    </div>
  );
};

export default RatingStars;
